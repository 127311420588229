@if $bulmaswatch-import-font {
  @import url("https://fonts.googleapis.com/css?family=Varela+Round&display=swap");
}

.card-header {
  border-bottom: solid 1px $primary;
}

.card {
  border: solid 1px $primary;
}

.progress::-webkit-progress-bar {
  background: $grey-lighter !important;
}

.button,
.card-header,
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6,
.menu-label,
.message-header,
.tabs,
.title {
  font-family: $font-heading;
  text-transform: uppercase;
}

.subtitle,
.tag,
th {
  font-family: $font-heading;
}

.box {
  border: solid 1px $primary;
}

.delete {
  $c: rgba($grey-light, 0.6);
  background-color: $c;

  &:hover {
    background-color: rgba($c, 0.85);
  }

  &::after,
  &::before {
    background-color: $black;
  }
}

.button {
  border-radius: 4px;
  padding-top: calc(0.375em + 1px);
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    $color-lightning: max((100% - lightness($color)) - 40%, 0%);
    $color-luminance: colorLuminance($color);
    $darken-percentage: $color-luminance * 70%;
    $desaturate-percentage: $color-luminance * 30%;
    $glow: 0 0 7px 1px $color;
    $transition: box-shadow 0.1s linear;

    &.is-#{$name} {
      &:not(.is-outlined) {
        border-width: 0;
      }
      transition: $transition;
      background: $color;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3) inset,
        0 1px 15px 0 rgba(0, 0, 0, 0.6) inset;

      &.is-hovered,
      &:hover {
        border-color: rgba(0, 0, 0, 0.25);
        transition: $transition;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3) inset,
          0 1px 15px 0 rgba(0, 0, 0, 0.6) inset, 0 0 3px 0 $color;
      }

      &.is-focused,
      &:focus {
        transition: $transition;
        border-color: $color;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2) inset,
          0 0 0 0 rgba(0, 0, 0, 0.5) inset, 0 0 7px 1px $color;
      }

      &.is-active,
      &:active {
        transition: $transition;
        border-color: rgba(0, 0, 0, 0.47);
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.7) inset,
          0 1px 15px 0 rgba(0, 0, 0, 0.9) inset;
      }
    }
  }
}

button.button,
input[type="submit"].button {
  padding-top: calc(0.375em + 1px);
}

.message {
  background-color: $background;
  border-radius: $radius;
  font-size: $size-normal;
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    $color-lightning: max((100% - lightness($color)) - 3%, 0%);
    $color-luminance: colorLuminance($color);
    $darken-percentage: $color-luminance * 70%;
    $desaturate-percentage: $color-luminance * 30%;

    &.is-#{$name} {
      background-color: darken($color, $color-lightning);

      .message-header {
        background-color: $color;
        color: $color-invert;
      }

      .message-body {
        border-color: $color;
        color: desaturate(
          lighten($color, $darken-percentage),
          $desaturate-percentage
        );
      }
    }
  }
}

.message.is-dark {
  background: $white;
}

.input:focus,
.select > *:focus,
.textarea:focus {
  box-shadow: 0 0 7px 1px $primary;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      box-shadow: 0 0 7px 0 $color;
    }
  }
}
